<template>
  <div class="bg-azul-default">
    <!-- Keep existing header with logo -->
    <div class="px-4 2xl:px-16">
      <nav class="container px-6 py-4 mx-auto md:flex md:justify-between md:items-center">
        <div class="flex items-center justify-between">
          <a href="https://parapaquetes.com/" class="text-xl font-bold text-gray-100 md:text-2xl hover:text-indigo-400">
            <img src="./assets/img/logo_mx.png" style="max-width: 140px" alt="logo de parapaquetes">
            <!-- <img :src="$t('header.primary.logo')" style="max-width: 140px" alt="logo de parapaquetes"> -->
          </a>
          <!--  Menu para movil -->
          <div @click="toggleNav" class="flex md:hidden">
            <button type="button" class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400">
              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
              </svg>
            </button>
          </div>
        </div>
        <!-- Menu para movil open: "block", Menu closed: "hidden" -->
        <ul :class="showMenu ? 'flex' : 'hidden'" class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:mt-0">
          <li>
            <p class="text-azul-light hover:text-indigo-400 text-sm font-semibold">{{$t("header.primary.country")}}</p>
            <LocaleSwitcher class="text-white h-10 bg-azul-default font-semibold"></LocaleSwitcher>
          </li>
        </ul>
      </nav>
    </div>

    <nav class="px-6 py-2 md:py-6 md:bg-white" :class="showMenu ? 'border-2 shadow-md rounded bg-white' : 'border-0'">
      <ul :class="showMenu ? 'flex flex-col' : 'hidden'" class="space-y-4 md:space-y-0 md:flex md:flex-wrap md:ml-10 md:mt-0">
        
        <!-- Cajas de Cartón Dropdown -->
        <li class="block relative md:mr-8" v-click-away="closeDropdown">
          <button @click="show = !show; show2 = false; show3 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Cajas de Cartón</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
          <div v-show="show" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
            <ul class="list-reset">
              <li v-for="item in boxesSubmenu" :key="item.link" class="relative">
                <a :href="'https://parapaquetes.com' + item.link" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Bolsas Dropdown -->
        <li class="block relative md:mr-8" v-click-away="closeDropdown2">
          <button @click="show2 = !show2; show = false; show3 = false;" class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Bolsas</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
          <div v-show="show2" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
            <ul class="list-reset">
              <li class="relative">
                <div class="font-semibold px-4 py-2 text-sm">Bolsas para Envíos</div>
                <template v-for="item in bagsSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>
                
                <div class="font-semibold px-4 py-2 text-sm mt-2">Bolsas de Plástico</div>
                <template v-for="item in plasticBagsSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>
              </li>
            </ul>
          </div>
        </li>

<!-- Sobres Para Envíos Dropdown -->
        <li class="block relative md:mr-8" v-click-away="closeDropdown3">
          <button @click="show3 = !show3; show = false; show2 = false; show4 = false; show5 = false;" 
                  class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Sobres Para Envíos</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
          <div v-show="show3" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
            <ul class="list-reset">
              <li class="relative">
                <!-- Sobres con Burbujas Section -->
                <div class="font-semibold px-4 py-2 text-sm">Sobres con Burbujas</div>
                <template v-for="item in bubbleEnvelopesSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>

                <!-- Sobres Packing List Section -->
                <div class="font-semibold px-4 py-2 text-sm mt-2">Sobres Packing List</div>
                <template v-for="item in packingListSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>

                <!-- Sobres Rígidos Section -->
                <div class="font-semibold px-4 py-2 text-sm mt-2">Sobres Rígidos</div>
                <a href="https://parapaquetes.com/collections/sobres-rigidos-kraft" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Sobres Rígidos Kraft
                </a>
                <a href="https://parapaquetes.com/collections/sobres-rigidos-blancos" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Sobres Rígidos Blancos
                </a>

                <!-- Sobres Kraft con Fuelle -->
                <a href="https://parapaquetes.com/collections/sobres-kraft-con-fuelle-para-envios" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100 mt-2">
                  Sobres Kraft con Fuelle
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Embalaje y Cintas Dropdown -->
        <li class="block relative md:mr-8" v-click-away="closeDropdown4">
          <button @click="show4 = !show4; show = false; show2 = false; show3 = false; show5 = false;" 
                  class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Embalaje y Cintas</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
          <div v-show="show4" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
            <ul class="list-reset">
              <li class="relative">
                <!-- Basic Packing Materials -->
                <template v-for="item in packingMaterialSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>

                <!-- Impresoras y Etiquetas Section -->
                <div class="font-semibold px-4 py-2 text-sm mt-2">Impresoras y Etiquetas</div>
                <template v-for="item in printersLabelsSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>

                <!-- Protección y Relleno Section -->
                <div class="font-semibold px-4 py-2 text-sm mt-2">Protección y Relleno</div>
                <template v-for="item in protectionFillSubmenu" :key="item.link">
                  <a :href="'https://parapaquetes.com' + item.link" 
                     class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                    {{ item.name }}
                  </a>
                </template>

                <!-- Rafia en Rollo -->
                <a href="https://parapaquetes.com/products/rafia-en-rollo-blanca" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100 mt-2">
                  Rafia en Rollo
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Empresa Dropdown -->
        <li class="block relative md:mr-8" v-click-away="closeDropdown5">
          <button @click="show5 = !show5; show = false; show2 = false; show3 = false; show4 = false;" 
                  class="flex items-center text-gray-500 bg-white rounded-md focus:outline-none">
            <span class="mr-4">Empresa</span>
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
            </svg>
          </button>
          <div v-show="show5" class="py-2 mt-2 bg-white rounded-md shadow-xl md:absolute w-72 top-auto left-0 min-w-full z-30">
            <ul class="list-reset">
              <li class="relative">
                <a href="https://parapaquetes.com/pages/sobre-nosotros" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Sobre Nosotros
                </a>
                <a href="https://parapaquetes.com/pages/preguntas-frecuentes" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Preguntas Frecuentes
                </a>

                <!-- Políticas Section -->
                <div class="font-semibold px-4 py-2 text-sm mt-2">Políticas</div>
                <a href="https://parapaquetes.com/policies/refund-policy" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Política de Reembolsos y Devoluciones
                </a>
                <a href="https://parapaquetes.com/policies/privacy-policy" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Política de Privacidad
                </a>
                <a href="https://parapaquetes.com/policies/terms-of-service" 
                   class="block px-4 py-2 text-sm text-gray-500 hover:bg-indigo-400 hover:text-indigo-100">
                  Términos y Condiciones
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- Static Links -->
        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/pages/contacto" class="text-gray-500 hover:text-indigo-400 font-normal">Contacto</a>
        </li>

        <li class="block md:mr-8">
          <a href="https://parapaquetes-files.s3.us-east-2.amazonaws.com/catalagos/empaques-para-envios-catalogo.pdf" 
             class="text-gray-500 hover:text-indigo-400 font-normal">
            Catálogo
          </a>
        </li>

        <li class="block md:mr-8">
          <a href="https://parapaquetes.com/pages/facturacion" class="text-gray-500 hover:text-indigo-400 font-normal">Facturación</a>
        </li>
      </ul>
    </nav>
  </div>
  <router-view/>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";
import LocaleSwitcher from "./components/LocalesSwitcher.vue";

export default {
  mixins: [ VueClickAway ],
  components: { LocaleSwitcher },
  data() {
    return {
      showMenu: false,
      show: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      showSubmenu1: false,
      showSubmenu2: false,
      showSubmenu3: false,
      showSubmenu4: false
    }
  },
  
  computed: {
    // Cajas de Cartón menu items
    boxesSubmenu() {
      return [
        { link: "/collections/cajas-de-carton-regulares", name: "Cajas Regulares" },
        { link: "/collections/cajas-de-carton-regulares-blancas", name: "Cajas Regulares Blancas" },
        { link: "/collections/cajas-de-carton-para-envios-con-pestanas", name: "Cajas para Envíos con Pestaña" },
        { link: "/collections/cajas-para-pizza", name: "Cajas para Pizza" },
        { link: "/collections/cajas-gaylord-de-carton", name: "Cajas Gaylord" }
      ]
    },
    
    // Bolsas menu items
    bagsSubmenu() {
      return [
        { link: "/collections/bolsas-para-envios-premium", name: "Bolsas Premium" },
        { link: "/collections/bolsas-para-envios-premium-con-portaguia", name: "Bolsas Premium con Portaguías" },
        { link: "/collections/bolsas-para-envios-ecologicas", name: "Bolsas Ecológicas/Recicladas" },
        { link: "/collections/bolsas-para-envios-rosas", name: "Bolsas Rosas" },
        { link: "/collections/bolsas-para-envios-azules", name: "Bolsas Azules" },
        { link: "/collections/bolsas-para-envios-negras", name: "Bolsas Negras" },
        { link: "/collections/bolsas-para-envios-moradas", name: "Bolsas Moradas" },
        { link: "/collections/bolsas-para-envios-verdes", name: "Bolsas Verdes" }
      ]
    },

    // Bolsas de Plástico submenu
    plasticBagsSubmenu() {
      return [
        { link: "/collections/bolsas-de-plastico-con-advertencia-de-asfixia", name: "Bolsas con Advertencia de Asfixia" },
        { link: "/collections/bolsas-de-plastico-ziploc", name: "Bolsas con Cierre Tipo Ziploc" },
        { link: "/collections/bolsas-de-celofan-con-adhesivo", name: "Bolsas de Celofán con Adhesivo" }
      ]
    },

    // Sobres con Burbujas menu items
    bubbleEnvelopesSubmenu() {
      return [
        { link: "/collections/sobres-con-burbuja-amarillos", name: "Sobres con Burbuja Amarillos" },
        { link: "/collections/sobres-con-burbuja-azules", name: "Sobres con Burbuja Azules" },
        { link: "/collections/sobres-con-burbuja-blancos-kraft", name: "Sobres con Burbuja Blancos" },
        { link: "/collections/sobres-con-burbuja-kraft", name: "Sobres con Burbuja Kraft" },
        { link: "/collections/sobres-con-burbuja-plateados", name: "Sobres con Burbuja Plateados" },
        { link: "/collections/sobres-con-burbuja-rosas", name: "Sobres con Burbuja Rosas" }
      ]
    },

    // Sobres Packing List submenu
    packingListSubmenu() {
      return [
        { link: "/collections/sobres-packing-list-abertura-lateral", name: "Abertura Lateral" },
        { link: "/collections/sobres-packing-list-abertura-superior", name: "Abertura Superior" },
        { link: "/collections/sobres-packing-list-enclosed", name: '"Enclosed"' }
      ]
    },

    // Material de embalaje menu items
    packingMaterialSubmenu() {
      return [
        { link: "/collections/cintas-adhesivas", name: "Cintas Adhesivas" },
        { link: "/collections/rollos-de-emplaye", name: "Film y Envolturas (Emplaye)" },
        { link: "/collections/flejes-y-grapas", name: "Flejes y Grapas" },
        { link: "/collections/herramientas-para-empaque-y-embalaje", name: "Herramientas y Accesorios" }
      ]
    },

    // Impresoras y Etiquetas submenu
    printersLabelsSubmenu() {
      return [
        { link: "/collections/impresoras-zebra", name: "Impresoras Zebra" },
        { link: "/collections/etiquetas-para-manejo-y-envio", name: "Etiquetas para Manejo y Envío" },
        { link: "/collections/etiquetas-para-impresoras-zebra", name: "Etiquetas Impresoras Zebra" },
        { link: "/collections/etiquetas-para-dymo", name: "Etiquetas Impresoras Dymo" },
        { link: "/collections/ribbons-para-impresoras", name: "Ribbons de Cera" }
      ]
    },

    // Protección y Relleno submenu
    protectionFillSubmenu() {
      return [
        { link: "/collections/esquineros-de-carton", name: "Esquineros de Cartón" },
        { link: "/products/relleno-biodegradable-para-cajas", name: "Relleno Biodegradable (Biofil/Micpac)" },
        { link: "/collections/rollo-de-burbuja-para-embalaje", name: "Rollo de Burbuja" },
        { link: "/products/rollo-de-carton-corrugado", name: "Rollo de Cartón Corrugado" },
        { link: "/collections/rollos-de-espuma-polyfoam", name: "Rollo de Espuma Polyfoam" },
        { link: "/products/rollo-de-papel-kraft", name: "Rollo de Papel Kraft" }
      ]
    }
  },

  methods: {
    closeDropdown() {
      this.show = false
    },
    closeDropdown2() {
      this.show2 = false
    },
    closeDropdown3() {
      this.show3 = false
    },
    closeDropdown4() {
      this.show4 = false
    },
    closeDropdown5() {
      this.show5 = false
    },
    toggleNav() {
      this.showMenu = !this.showMenu
    }
  }
}

</script>

<style>
  /* For 1024 Resolution */  
  @media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)
{
.md\:mr-8 {margin-right:0.9rem !important;}
.mr-4 {margin-right:0rem !important}
}  

</style>
<style scoped>
.inset-l-full {
}

@media (min-width: 768px) { 
  .inset-l-full {
    left: 100%;
}
}
</style>